<template>
  <v-menu
    ref="rMenuTime"
    v-model="menuTime"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template #activator="{ on, attrs }">
      <ValidationProvider
        :name="$t(label)"
        :rules="required ? 'required' : ''"
        :vid="vid"
        :ref="refName"
        slim
      >
        <v-text-field
          slot-scope="{ errors, valid }"
          :error-messages="errors"
          :success="valid"
          v-model="time"
          :label="$t(label)"
          :disabled="disabled"
          outlined
          dense
          prepend-inner-icon="mdi-clock-outline"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </ValidationProvider>
    </template>

    <v-time-picker
      v-if="menuTime"
      v-model="time"
      full-width
      format="24hr"
      @change="setTime"
    ></v-time-picker>
  </v-menu>
</template>
<script lang="ts">
import { Vue, Component, Prop, VModel } from "vue-property-decorator";

@Component
export default class FormFieldTimePicker extends Vue {
  menuTime = false;
  @VModel({ type: String }) time!: string;
  @Prop({ type: String, default: "time" }) readonly label!: string;
  @Prop({ type: String, default: "time" }) readonly vid!: string;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly disabled!: boolean;

  setTime(sValue: string) {
    this.$emit("input", sValue);
    this.$emit("change", sValue);
    this.menuTime = false;
  }

  get refName() {
    return this.$_.camelCase(`field-${this.vid}`);
  }
}
</script>
